<template>
  <div>
    <v-toolbar color="accent" dark dense>
      <v-toolbar-title>Fixture Details</v-toolbar-title>
    </v-toolbar>
    <v-row dense class="mt-5">
      <v-col cols="12" md="5">
        Estimated units:
      </v-col>
      <v-col cols="12" md="3">
        <v-chip outlined label color="primary" class="mx-2">{{
          quote.estimated_volumes
        }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense class="mt-5">
      <v-col cols="12" md="5">
        Wiring:
      </v-col>
      <v-col cols="12" md="3">
        <v-chip outlined label color="primary" class="mx-2">{{
          quote.project_wiring_name
        }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense class="mt-5">
      <v-col cols="12" md="5">
        Custom backplate:
      </v-col>
      <v-col cols="12" md="2">
        <v-chip outlined label color="primary" class="mx-2">{{
          quote.custom_backplate ? "Yes" : "No"
        }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense class="mt-5">
      <v-col cols="12" md="5">
        Test Instrumentation:
      </v-col>
      <v-col cols="12" md="2">
        <v-chip outlined label color="primary" class="mx-2">{{
          quote.test_instrumentation_selection_and_integration ? "Yes" : "No"
        }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense class="mt-5">
      <v-col cols="12" md="5">
        Test Software:
      </v-col>
      <v-col cols="12" md="3">
        <v-chip outlined label color="primary" class="ml-2">{{
          quote.test_software_development ? "Yes" : "No"
        }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense class="mt-4">
      <v-col cols="12">
        <span>
          <v-textarea
            dense
            label="Additional Services Details"
            rows="4"
            readonly
            auto-grow
            outlined
            flat
            :value="
              quote.additional_services_details
                ? quote.additional_services_details
                : 'Not indicated'
            "
        /></span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "FixtureDetailsSection",
  props: {
    quote: {
      type: Object,
      default: null,
    },
  },
};
</script>
